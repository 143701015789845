import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom';
import { PageContext } from '../../context/PageState';
import Pills from '../Pills';


interface ICard {
    blurb: any | {[key: string]: unknown};
    className?: string | undefined;
    compatibility: string[];
    key: "string";
    image?: string;
    images?: {
        src: string;
    }[];
    objectID?: string;
    slug: "string";
    title: string;
}

const Cards = (props: ICard) => {
    const { theme } = useContext(PageContext);
    let imageSrc: string;

    if (props.image !== undefined) {
        imageSrc = props.image
    } else if (props.images !== undefined) {
        imageSrc = props.images[0].src
    } else {
        return <Fragment key={(props.objectID !== undefined) ? props.objectID : props.key}></Fragment>;
    }

    return <div className={`${props.className !== undefined ? props.className : "col-12 col-md-6 col-lg-4 mb-5"}`} key={(props.objectID !== undefined) ? props.objectID : props.key}>
        <div className="card h-100 pb-2">
            <Link
                to={`/devices/${props.slug}`}
            >
                <img
                    className="card-img-top"
                    src={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,w_300,b_rgb:${theme.mode ? "111111" : "ececec"}/v1/${imageSrc}`}
                    srcSet={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,w_600,b_rgb:${theme.mode ? "111111" : "ececec"}/v1/${imageSrc}, 2x`}
                    alt={`Thumbnail of ${props.title}`}
                />
            </Link>
            <div className="card-body flex-grow-1">
                <div className='compatible devices'>
                    <Pills
                        className="low-em-bg"
                        compatibility={props.compatibility}
                        small
                    />
                </div>
                <h3 className='h5 fw-bold'>
                    {props.title && props.title}
                </h3>
                <p className="small mb-0">
                    {
                        Object.keys(props.blurb).length &&
                        props.blurb.default ?
                            props.blurb.default :
                            props.blurb
                    }
                </p>
            </div>
            <div className="card-footer bg-transparent border-0">
                {props.slug && props.slug !== null ?
                    <Link
                        to={`/devices/${props.slug}`}
                        className='btn btn-primary'
                    >
                        Learn More
                    </Link> :
                    <button className={`btn ${theme.mode ? "btn-outline-light" : "btn-outline-dark"} disabled `} disabled>
                        Coming Soon
                    </button>
                }
            </div>
        </div>
    </div>
}

export default Cards